<template>
  <AppHeader />
  <Suspense>
    <template #default>
      <Searchs :search_id="search_id"/>
    </template>
    <template #fallback>
      <Loding />
    </template>
  </Suspense>
  <AppFooter />
</template>

<script>
// @ is an alias to /src
import { defineAsyncComponent, Suspense } from "vue";
import Loding from "@/components/Include/Loding.vue";
import AppHeader from "@/components/Include/AppHeader.vue";
import AppFooter from "@/components/Include/AppFooter.vue";
const Searchs = defineAsyncComponent(() =>
  import('@/components/Pages/Searchs.vue')
)
export default {
  name: "Search",
  props: {
    search_id: String
  },
  components: {
    Suspense,
    Loding,
    AppHeader,
    AppFooter,
    Searchs,
    
  },
};
</script>